@use 'modules/components';
@use 'modules/layouts_v2.modules.scss';
@use 'variables';
@use 'mixin';

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&family=Quicksand:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;600;700&display=swap');

body {
  font-family: variables.$font-primary !important;
  background-color: variables.$grey-05 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.align-center {
  align-items: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

table {
  table-layout: fixed;
  thead {
    tr {
      border-bottom: 3px solid variables.$grey-05;
      td {
        vertical-align: top;
        &.no-width {
          min-width: 0px !important;
          width: 0px !important;
          overflow: hidden !important;
        }
      }
    }
  }
  tbody {
    tr {
      border-bottom: 3px solid variables.$grey-05;
      td {
        max-width: 210px;
        padding: 8px 0;
        font-family: variables.$font-primary;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.31px;
        color: variables.$black;
        height: 40px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        vertical-align: top;
        transition: all 0.2s ease-in-out;
        // transition: all ease-in-out 0.2s !important;
        &.no-width {
          min-width: 0px !important;
          width: 0px !important;
          overflow: hidden !important;
        }

        &:hover {
          z-index: 9999;
          white-space: pre-wrap !important;
        }
      }

      &:hover {
        background: rgba(0, 0, 0, 0.05);
      }

      .action-tables {
        opacity: 0.6;
        transition: opacity 0.2s ease-in-out;
        img {
          height: 22px;
          width: 22px;
        }
      }

      &:hover {
        td.action-tables {
          opacity: 1;
        }
      }
    }
  }
}

.MuiButtonBase-root {
  outline: none !important;
}

.icon-hover-button {
  cursor: pointer;
}

.btn-submit:disabled {
  opacity: 0.7;
}

.font-weight-medium {
  font-weight: 500;
}

.modal-delete-style {
  border-radius: 15px;
  .header-modal {
    padding: 20px 60px;
    height: 80px;
    .text-title {
      text-align: center;
      text-transform: uppercase;
      font-size: 18px;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 2.25px;
      font-weight: bold;
      text-align: center;
      color: #191a1f;
    }

    .close-button {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
      margin-top: 20px;
      margin-right: 20px;
      font-size: 25px;
      transition: ease-in-out 0.2s;
      color: #e0e0e0;
    }
  }

  button {
    background: #fff;
    outline: none;
    border: none;
    transition: all 0.2s ease-in-out;
  }

  button:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}

.md-text {
  font-family: variables.$font-primary;
  font-size: 14px !important;
}

.small-text {
  font-family: variables.$font-primary;
  font-size: 12px !important;
}

.cursor-pointer {
  cursor: pointer;
}

@media (min-width: 1320px) {
  .container-xxl {
    max-width: 1320px;
    margin: auto;
  }
}
