@use '../variables';

// Input
.search-input {
  &.input-right {
    .MuiInputBase-input {
      text-align: right;
    }
  }

  .MuiInputBase-input {
    font-size: 14px !important;
  }

  .MuiInput-underline:before {
    border-bottom: 1px solid rgba(35, 31, 32, 0.4) !important;
  }

  .MuiInput-underline:after {
    border-bottom: 1px solid #000 !important;
  }

  &.MuiInput-underline:before {
    border-bottom: 1px solid rgba(35, 31, 32, 0.4) !important;
  }

  &.MuiInput-underline:after {
    border-bottom: 1px solid #000 !important;
  }

  &.search-input--select {
    min-width: 120px;
  }
}

// Button
.button-search {
  border-radius: 5px !important;
  background: variables.$black !important;
  outline: none !important;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0;

  img {
    width: 22px;
    height: 22px;
  }

  .button-text {
    color: variables.$primary !important;
    font-size: 12px;
  }

  &:hover {
    background: variables.$black;
  }
}
.button-dropdown {
  .dropdown-menu {
    min-width: 100%;
  }
  .button-dropdown__custom {
    display: flex;
    border: 2px solid variables.$black !important;
    background-color: variables.$white;
    border-radius: 5px !important;
    color: variables.$black !important;
    height: 40px;
    border: none;
    justify-content: center;
    align-items: center;
    &.mobile {
      height: 33px;
      padding-left: 8px !important;
    }

    &:after {
      margin-top: 0 !important;
    }
  }
  .button-dropdown__custom ~ .dropdown-menu.show {
    .dropdown-item:focus {
      background-color: variables.$dark-blue !important;
      color: variables.$white !important;
    }
  }

  .button-dropdown__sort-custom {
    background-color: variables.$black;
    padding: 0;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px !important;
    color: variables.$white;
    border: none;
    &.mobile {
      height: 33px;
      padding-left: 8px !important;
    }

    img {
      width: 14px;
      height: 14px;
    }
  }
  .button-dropdown__sort-menu:is(.show) {
    transform: translate3d(-140px, 40px, 0) !important;
  }

  .button-dropdown__setting-custom {
    display: flex;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: variables.$yellow;
    border-radius: 5px !important;
    color: variables.$white;
    border: none;
    &.mobile {
      height: 33px;
      padding-left: 8px !important;
    }
  }
  .button-dropdown__setting-menu:is(.show) {
    transform: translate3d(calc(-230px + 40px), 36px, 0) !important;
    div {
      width: 230px;
      padding: 0px;

      .MuiFormControlLabel-root {
        margin: 0 !important;

        .MuiTypography-body1 {
          font-size: 12px !important;
          font-family: variables.$font-primary;
          margin-left: 5px !important;
        }
      }
    }
  }

  .text {
    font-size: 15.2px;
    font-weight: 700;
    letter-spacing: -0.33px;
    text-transform: capitalize !important;
    text-align: center;
    color: variables.$black;
  }
  .dropdown-toggle::after {
    margin-top: 10px;
  }
  .menu-custom {
    font-size: 14px;
    letter-spacing: -0.33px;
    color: variables.$grey;
    &:focus {
      background: unset;
    }
    &.menu-choose {
      font-size: 14px;
      letter-spacing: -0.33px;
      background-color: variables.$black;
      color: variables.$primary;
    }
  }
}

.btn-submit {
  border-radius: 5px;
  font-family: variables.$font-primary !important;
  background-color: variables.$black !important;
  width: 100%;
  border: none;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  color: variables.$primary !important;
  text-transform: capitalize !important;
  padding: 15px 0;
}

.cust-btn-orange {
  display: flex !important;
  align-items: center !important;
  background-color: variables.$orange !important;
  border-radius: 9.5px;
  color: variables.$white;
  border: none !important;
  outline: none !important;
}
.cust-btn-yellow-outline {
  display: flex !important;
  align-items: center !important;
  color: variables.$primary !important;
  background: transparent !important;
  border: 2px solid variables.$black !important;
  border-radius: 5px;
  color: variables.$black;
  outline: none !important;
}
.cust-btn-yellow {
  border: 0 !important;
  outline: 0 !important;
  background: variables.$black !important;
  border-radius: 5px;

  .MuiButton-label {
    letter-spacing: 1.5px;
    font-size: 14px;
    padding: 5px 0;
    font-weight: 700;
    color: variables.$primary !important;
  }
}

.btn-clnfilter {
  font-family: variables.$font-primary;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 14px;
  width: fit-content;
  margin-top: 14px;
  .btn-clnfilter__title {
    color: variables.$grey;
    font-size: 14px;
    font-weight: bold;
    margin-left: 10px;
  }
}
@media screen and (max-width: 992px) {
  .btn-clnfilter {
    margin-top: 0;
  }
}

// Limit
.show-limit-title {
  font-family: variables.$font-primary;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 8px;
}
.show-limit {
  padding: 0 8px;
  border-right: 1px solid variables.$black;
  cursor: pointer;
  font-family: variables.$font-primary;
  font-size: 12px;
  text-align: center;

  &.active {
    color: variables.$orange;
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    border-right: none;
  }
}

// Table
.table-scroll {
  width: 100%;
  display: block;
  margin: 0 auto;
  overflow-x: auto;
  overflow-y: auto;

  thead > tr {
    display: table-row;
    td {
      font-family: variables.$font-primary, sans-serif;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: -0.31px;
      color: variables.$black;
      text-transform: uppercase;
      word-wrap: break-word;
    }
  }
  .column-number {
    min-width: 70px;
  }

  .column-title {
    min-width: 120px;
    word-wrap: break-word;
  }

  .column-status {
    min-width: 70px;
    word-wrap: break-word;
  }

  .column-title-mid {
    font-family: variables.$font-primary;
    font-size: 14px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: -0.31px;
    text-align: left;
    color: variables.$black;
    text-transform: uppercase;
    min-width: 140px;
    word-wrap: break-word;
  }

  .column-title-long {
    font-family: variables.$font-primary;
    font-size: 14px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.31px;
    text-align: left;
    color: variables.$black;
    text-transform: uppercase;
    min-width: 210px;
    word-wrap: break-word;
  }

  .column-action {
    min-width: 100px;
    text-align: center;
  }

  .last-of-content {
    display: table-cell;
  }

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: variables.$white-05;
    border-radius: 8px;
  }
}
.column-title {
  font-family: variables.$font-primary;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.31px;
  text-align: left;
  color: variables.$black;
  text-transform: uppercase;

  &.checkbox {
    width: 60px !important;
  }
}
.last-of-content {
  display: none;
}

// Status
.yellow-status {
  font-family: variables.$font-primary;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: -0.31px;
  color: variables.$yellow;
}
.green-status {
  font-family: variables.$font-primary;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: -0.31px;
  color: variables.$success;
}
.red-status {
  font-family: variables.$font-primary;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: -0.31px;
  color: variables.$danger;
}
.grey-status {
  font-family: variables.$font-primary;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: -0.31px;
  color: variables.$grey-05;
}

// Modal
.dialog-title {
  font-family: variables.$font-primary;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 2.25px;
  text-align: center;
  color: variables.$black;
}
.delete-dialog-button {
  width: 50%;
  padding: 16px;
  text-transform: uppercase;
  border-top: solid 5px variables.$grey-05;
  text-align: center;
  font-family: variables.$font-primary;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 2px;
  cursor: pointer;
  &.yes-button {
    color: variables.$black;
  }

  &.no-button {
    color: variables.$danger;
  }
}
@media screen and (max-width: 664px) {
  .responsive-title.MuiialogTitle-root {
    min-width: 400px !important;
  }
}
