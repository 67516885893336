@use '../variables';

$bg-default: variables.$secondary;
$header-height: 98px;

.layout-v2 {
  margin: 0;

  /* @ Header */
  .layout-v2__header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    width: 100%;
    padding: 24px 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $bg-default;
    box-shadow: -1px -10px 33px 16px rgba(0, 0, 0, 0.44);

    .layout-v2__header-brand {
      display: flex;
      align-items: center;

      .layout-v2__header-logo {
        width: 40px;
        height: 40px;
      }
      .layout-v2__header-title {
        font-family: variables.$font-secondary;
        font-size: 22px;
        color: variables.$white;
        margin-left: 14px;
      }
    }

    .layout-v2__header-profiles {
      position: relative;
      .layout-v2__header-profile {
        display: flex;
        align-items: center;
        cursor: pointer;

        .layout-v2__header-photo {
          width: 50px;
          height: 50px;
        }
        .layout-v2__header-name {
          color: variables.$white;
          font-size: 16px;
          margin: 0 12px;
        }

        &:after {
          content: '';
          display: inline-block;
          border-left: 0.4em solid transparent;
          border-right: 0.4em solid transparent;
          border-top: 0.4em solid variables.$white;
        }
      }

      .header-profile--detail {
        position: absolute;
        background-color: variables.$grey-05;
        box-shadow: 7px 13px 23px -10px rgba(0, 0, 0, 0.46);
        border-radius: 5px;
        visibility: hidden;
        transition: opacity 0.3s ease-in-out, visibility 0.5s ease-in-out;
        top: $header-height - 30;
        right: 0;
        opacity: 0;
        width: 240px;

        &.show {
          opacity: 1;
          visibility: visible;
        }

        .header-profile--detail__profile {
          padding: 18px 24px;
          .header-profile--detail__title {
            font-weight: 700;
            font-size: 14px;
          }
          .header-profile--detail__name {
            font-weight: 500;
            font-size: 16px;
          }
        }

        .header-profile--detail__logout {
          margin-top: 8px;
          padding: 12px 0;
        }
      }
    }
  }

  /* @ Sidebar */
  .layout-v2__side {
    position: fixed;
    height: calc(100% - $header-height);
    top: $header-height;
    padding: 18px 8px;
    width: 80px;
    background-color: $bg-default;
    transition: all 0.4s ease-in-out;
    z-index: 19;

    .layout-v2__side-expand {
      display: flex;
      justify-content: flex-end;
      padding: 14px 18px;
      margin-bottom: 14px;

      .layout-v2__side-expandicon {
        cursor: pointer;
        transform: rotate(-180deg);
        transition: transform 0.2s ease-in-out;

        &.rotate {
          transform: rotate(0);
        }
      }
    }

    .layout-v2__side-items {
      margin: 0;
      padding: 0;
      list-style-type: none;

      .layout-v2__side-item {
        text-decoration: none;
        color: white;
        padding: 14px 18px;
        position: relative;
        display: flex;
        flex-direction: column;

        &.active {
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            height: 100%;
            width: 100%;
            background-color: variables.$white;
            opacity: 0.15;
            border-radius: 8px;
          }
          .layout-v2__side-itemcontainer {
            .layout-v2__side-itemwrapper {
              .layout-v2__side-title {
                color: variables.$primary;
              }
            }
            .layout-v2__side-dropdown {
              color: variables.$primary;
            }
          }
        }

        .layout-v2__side-menuexpand {
          .layout-v2__side-itemcontainer {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            overflow: hidden;

            .layout-v2__side-itemwrapper {
              display: flex;
              align-items: center;

              .layout-v2__side-icons {
                width: 24px;
                height: 24px;
                object-fit: contain;
              }

              .layout-v2__side-title {
                margin-left: 14px;
                visibility: hidden;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-transform: capitalize;
                opacity: 0;
                letter-spacing: 0.5px;
                font-weight: 600;
                font-size: 14px;
                width: 0%;
                transition: width 0.2s ease-in-out, opacity 0.2s ease-in-out, visibility 0.4s ease-in-out;
              }
            }
            .layout-v2__side-dropdown {
              visibility: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              opacity: 0;
              transform: rotate(0deg);
              font-size: 14px;
              transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out, visibility 0.4s ease-in-out;
              &.rotate {
                transform: rotate(-180deg);
              }
            }
          }

          .layout-v2__side-menu {
            position: absolute;
            left: 72px;
            height: 100%;
            width: 220px;
            top: 0;
            padding: 14px 0px;
            background-color: variables.$secondary;
            visibility: hidden;
            opacity: 0;
            transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, background-color 0.2s ease-in-out;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;

            .layout-v2__side-menus {
              display: flex;
              padding-right: 18px;
              align-items: center;
              justify-content: space-between;
              cursor: pointer;

              .layout-v2__side-menutitle {
                margin-left: 14px;
                color: variables.$white;
                text-decoration: none;
                overflow: hidden;
                text-overflow: ellipsis;
                text-transform: capitalize;
                letter-spacing: 0.5px;
                font-weight: 600;
                font-size: 14px;
              }

              .layout-v2__side-menudropdown {
                font-size: 14px;
                transform: rotate(0deg);
                transition: transform 0.2s ease-in-out;
                &.rotate {
                  transform: rotate(-180deg);
                }
              }
            }

            &.active {
              background-color: #444041;
              .layout-v2__side-menus {
                .layout-v2__side-menutitle,
                .layout-v2__side-menudropdown {
                  color: variables.$primary;
                }
              }
            }

            .layout-v2__side-menuchild {
              display: block;
              white-space: nowrap;
              margin-left: 14px;
              overflow: hidden;
              color: variables.$white;
              padding-top: 12px;
              font-size: 14px;
              text-decoration: none;
              text-transform: capitalize;
              font-weight: 600;

              &.active {
                color: variables.$primary;
              }
            }
          }
        }
        .layout-v2__side-child {
          overflow: hidden;
          .layout-v2__side-title {
            white-space: nowrap;
            overflow: hidden;
            padding-top: 12px;
            color: variables.$white;
            font-size: 14px;
            text-decoration: none;
            margin-left: 40px;
            text-transform: capitalize;
            font-weight: 600;
          }

          &.active {
            .layout-v2__side-title {
              color: variables.$primary;
            }
          }

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    &.expand {
      width: 300px;
      padding: 18px 16px;
      overflow-y: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-track {
        background: variables.$secondary;
      }
      &::-webkit-scrollbar-thumb {
        transition: 0.2s ease-in-out;
        visibility: hidden;
        background-color: #534c4d;
      }
      &:hover {
        &::-webkit-scrollbar-thumb {
          visibility: visible;
        }
      }
    }

    &.active,
    &.expand {
      .layout-v2__side-items {
        .layout-v2__side-item {
          .layout-v2__side-itemcontainer {
            .layout-v2__side-itemwrapper {
              .layout-v2__side-title {
                visibility: visible;
                width: 100%;
                opacity: 1;
              }
            }

            .layout-v2__side-dropdown {
              opacity: 1;
              visibility: visible;
            }
          }
          .collapse {
            &.show {
              display: initial;
            }
          }
        }
      }
    }

    &:not(.expand) {
      .layout-v2__side-item:hover {
        .layout-v2__side-menu {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  /* @ Content */
  .layout-v2__content {
    margin-left: 80px;
    margin-top: $header-height;
    transition: margin-left 0.2s ease-in-out;

    &.expand {
      margin-left: 300px;
    }
    .layout-v2__content-body {
      // height: calc(100vh - $header-height);
      height: 100%;
      width: 100%;
      padding: 20px;
      background: variables.$grey-05;

      .layout-v2__content-title {
        font-family: variables.$font-primary;
        font-size: 28px;
        font-weight: 700;
        letter-spacing: 2px;
        color: variables.$black;
        text-transform: capitalize;
        margin-bottom: 28px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .layout-v2__header {
      .layout-v2__header-brand {
        .layout-v2__header-action {
          font-size: 18px;
          color: variables.$white;
          margin-right: 14px;
        }
      }
      .layout-v2__header-profiles {
        .layout-v2__header-profile {
          .layout-v2__header-name {
            display: none;
          }

          &:after {
            display: none;
          }
        }
      }
    }

    .layout-v2__side {
      width: 300px;
      left: -300px;

      &.active {
        left: 0;
      }

      .layout-v2__side-expand {
        display: none;
      }

      &:not(.expand) {
        .layout-v2__side-item:hover {
          .layout-v2__side-menu {
            visibility: hidden;
            opacity: 0;
          }
        }
      }
    }

    .layout-v2__overlay {
      position: fixed;
      height: calc(100% - $header-height);
      top: $header-height;
      left: 0;
      z-index: 18;
      background-color: variables.$black;
      opacity: 0.4;

      &.active {
        width: 100%;
      }
    }

    .layout-v2__content {
      margin-left: 0;
      &.expand {
        margin-left: 0;
      }
    }
  }
}

.error-container-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 98px - 88px);
  width: 100%;
  flex-direction: column;
  font-family: variables.$font-primary;
  text-align: center;
  .error__title {
    font-size: 30px;
    font-weight: 700;
  }
  .error__subtitle {
    font-size: 18px;
    width: 40%;
  }
  @media screen and (max-width: 500px) {
    .error__subtitle {
      width: 100%;
      padding: 0 20px;
    }
  }

  .error__button-3 {
    background: variables.$black;
    outline: none;
    border: none;
    padding: 12px 30px;
    border-radius: 5px;
    margin-top: 24px;
    color: variables.$primary;
    transition: all 0.2s ease-in-out;
    &:hover,
    &:focus {
      opacity: 0.75;
    }
  }
}

.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  font-family: variables.$font-primary;
  text-align: center;
  .error__title {
    font-size: 30px;
    font-weight: 700;
  }
  .error__subtitle {
    font-size: 18px;
    width: 40%;
  }
  @media screen and (max-width: 500px) {
    .error__subtitle {
      width: 100%;
      padding: 0 20px;
    }
  }

  .error__button-3 {
    background: variables.$black;
    outline: none;
    border: none;
    padding: 12px 30px;
    border-radius: 5px;
    margin-top: 24px;
    color: variables.$primary;
    transition: all 0.2s ease-in-out;
    &:hover,
    &:focus {
      opacity: 0.75;
    }
  }
}
